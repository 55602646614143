<template>
    <v-card class="mx-2">
        <v-card-title>
            <span class="headline mt-4">{{currentUser.lastName}} Profile</span>
        </v-card-title>
        <v-card-text>
            <v-container >
            <v-row>
                <v-col
                cols="12"
                sm="6"
                >
                <v-text-field
                    label="First Name"
                    type="text"
                    v-model="currentUser.firstName"
                    disabled
                />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                <v-text-field
                    label="Last Name"
                    type="text"
                    v-model="currentUser.lastName"
                    disabled
                />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                <v-text-field
                    label="Telephone"
                    type="telephone"
                    v-model="currentUser.phone"
                    class="mt-n6"
                    disabled
                />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                <v-text-field
                    label="Email"
                    type="email"
                    v-model="currentUser.email"
                    class="mt-n6"
                    disabled
                />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                  <v-text-field
                    label="Position"
                    type="text"
                    v-model="currentUser.position"
                    disabled
                    class="mt-n6"
                  />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                  <v-text-field
                    label="Center"
                    type="text"
                    v-model="currentUser.center"
                    disabled
                    class="mt-n6"
                  />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                  <v-text-field
                    label="Status"
                    type="text"
                    v-model="currentUser.status"
                    disabled
                    class="mt-n6"
                  />
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                <v-text-field
                    label="Username"
                    type="text"
                    v-model="currentUser.username"
                    class="mt-n6"
                    disabled
                />
                </v-col>
                <v-col cols="12" sm="12">
                <v-select
                    v-model="currentUser.roles"
                    :items="currentUser.roles"
                    item-text="name"
                    item-value="id"
                    chips
                    label="Roles"
                    multiple
                    class="mt-n6"
                    disabled
                ></v-select>
                </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
  }),
  computed: {
    ...mapState('store', ['currentUser'])
  }
}
</script>
